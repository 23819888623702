var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "container" }, [
    _c("div", { staticClass: "scoreBox" }, [
      _c("div", { staticClass: "title", attrs: { title: _vm.scoreTitle } }, [
        _vm._v(_vm._s(_vm.scoreTitle)),
        _c("span", { staticClass: "color" }, [_vm._v("*")]),
      ]),
      _c(
        "div",
        { staticClass: "level" },
        [
          _c("i-rate", {
            attrs: { color: "#ffcd30", size: 23, readonly: _vm.disabled },
            on: {
              change: function ($event) {
                return _vm.scoreMethod(_vm.scoreVal)
              },
            },
            model: {
              value: _vm.scoreVal,
              callback: function ($$v) {
                _vm.scoreVal = $$v
              },
              expression: "scoreVal",
            },
          }),
          _c("span", { staticClass: "ml10" }, [_vm._v(_vm._s(_vm.scoreLevel))]),
        ],
        1
      ),
    ]),
    !_vm.isRequired || (_vm.isRequired && _vm.scoreVal < 3)
      ? _c("div", { staticClass: "text" }, [
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.scoreDescr,
                expression: "scoreDescr",
              },
            ],
            attrs: { placeholder: _vm.placeholder, disabled: _vm.disabled },
            domProps: { value: _vm.scoreDescr },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.scoreDescr = $event.target.value
              },
            },
          }),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }