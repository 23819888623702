<template>
  <section class="container">
    <i-header
      :title="平台满意度调查"
      :showBack="true"
      @back-event="toBack"
    ></i-header>
    <main class="content" ref="wrapper">
      <div class="title">平台满意度调查</div>
      <div class="scoreItem">
        <div class="box" v-for="(item, index) in scoreData" :key="index">
          <score
            :scoreTitle="item.title"
            :placeholder="item.descr"
            :isRequired="item.isRequired"
            ref="surpplier"
            :scoreVal="item.scoreVal"
            :scoreDescr="item.scoreDescr"
            :scoreLevel="item.scoreLevel"
            :disabled="isDisabled"
          ></score>
        </div>
      </div>
    </main>
    <footer class="footer_module">
      <button :class="isDisabled?'disabled':'budget_total'" @click="submit" :disabled="isDisabled">提交</button>
    </footer>
  </section>
</template>

<script>
import score from "./score.vue";
export default {
  name: "APPScore",
  components: {
    score,
  },
  data() {
    return {
      scoreTitle: "",
      scoreTitle2: "",
      placeholder: "",
      placeholder2: "",
      scoreData: [],
      processparams: {},
      miceId: this.$smartStorage.get("miceInfo").MiceId,
      isDisabled:false,// 提交按钮禁用
    };
  },
  mounted() {
    this.getScore();
    this.processparams = JSON.parse(this.$route.query.processparams);
  },
  methods: {
    toBack() {
      this.$router.back();
    },
    getScore() {
      let params = {
        MiceId: this.miceId,
      };
      this.$service.getScore(params).then((res) => {
        const scoreParams = [];
        if(res.success&&res.content){
                if(res.content.length>0){
                    this.isDisabled=true;
                    res.content.forEach((item) => {
                    scoreParams.unshift({
                        scoreVal: item.score,
                        scoreDescr: item.scoreDescr,
                        scoreLevel: item.scoreTxt,
                    });
                });
            }
            this.initScoreConfig(scoreParams);
        }else{
            this.$itoast.fail("订单错误,请联系管理员！");
        }

      });
    },
    initScoreConfig(scoreParams) {
        this.$service.getSurvay().then((res) => {
            if(scoreParams.length>0){
                res.content.forEach((item, index) => {
                item.scoreVal = scoreParams[index].scoreVal;
                item.scoreDescr = scoreParams[index].scoreDescr;
                item.scoreLevel = scoreParams[index].scoreLevel;
                return item;
            });
            this.scoreData = res.content;
        }else{
            this.scoreData = res.content;
        }
      });
    },
    submit() {
      let obj = {};
      let params = [];
      for (let i = 0; i < this.$refs.surpplier.length; i++) {
        if (this.$refs.surpplier[i].scoreVal == undefined) {
          this.$itoast("第" + (i + 1) + "条评价为必评项");
          return;
        }
        if (
          this.$refs.surpplier[i].isRequired &&
          this.$refs.surpplier[i].scoreVal < 3 &&
          !this.$refs.surpplier[i].scoreDescr
        ) {
          this.$itoast("请阐述第" + (i + 1) + "条评价原因或者建议");
          return;
        }
        obj = {
          miceId: this.miceId,
          configId: this.scoreData[i].id,
          score: this.$refs.surpplier[i].scoreVal,
          scoreTxt: this.$refs.surpplier[i].scoreLevel,
          scoreDescr: this.$refs.surpplier[i].scoreDescr,
        };
        params.push(obj);
      }

      this.$idialog
        .confirm({
          title: "提示",
          message: "是否确认提交评分",
        })
        .then(() => {
          if (confirm) {
            this.$service.SaveSurvay(params).then((res) => {
              this.loading = false;
              if (res && res.success) {
                this.$router.back();
              } else {
                this.$itoast.fail("订单错误,请联系管理员！");
              }
            });
          }
        });
    }
  },
};
</script>

<style scoped="scoped">
.container {
  font-size: 20px;
  width: 100%;
}
.title {
  color: #2d82f0;
  padding-left: 0.15rem;
  font-size: 0.14rem;
  margin-top: 0.1rem;
}
.footer_module {
  position: fixed;
  bottom: .5rem;
  left: 0;
  width: 100%;
  height: 0.44rem;
  background: #fff;
  display: flex;
  -webkit-display: flex;
  justify-content: center;
}
.footer_module .budget_total {
  background: #2d82f0;
  width: 90%;
  height: 100%;
  text-align: center;
  line-height: 0.44rem;
  font-size: 0.16rem;
  color: #fff;
  border-radius: 0.04rem;
}
.disabled{
    background: #cccccc;
    width: 80%;
    height: 100%;
    text-align: center;
    line-height: 0.44rem;
    font-size: 0.16rem;
    color: #fff;
    border-radius: 0.04rem;
}
.box {
  border-bottom: 1px solid #cccccc;
  text-align: left;
}
.box:last-of-type {
  border: none;
}
</style>
