var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "container" },
    [
      _c("i-header", {
        attrs: { title: _vm.平台满意度调查, showBack: true },
        on: { "back-event": _vm.toBack },
      }),
      _c("main", { ref: "wrapper", staticClass: "content" }, [
        _c("div", { staticClass: "title" }, [_vm._v("平台满意度调查")]),
        _c(
          "div",
          { staticClass: "scoreItem" },
          _vm._l(_vm.scoreData, function (item, index) {
            return _c(
              "div",
              { key: index, staticClass: "box" },
              [
                _c("score", {
                  ref: "surpplier",
                  refInFor: true,
                  attrs: {
                    scoreTitle: item.title,
                    placeholder: item.descr,
                    isRequired: item.isRequired,
                    scoreVal: item.scoreVal,
                    scoreDescr: item.scoreDescr,
                    scoreLevel: item.scoreLevel,
                    disabled: _vm.isDisabled,
                  },
                }),
              ],
              1
            )
          }),
          0
        ),
      ]),
      _c("footer", { staticClass: "footer_module" }, [
        _c(
          "button",
          {
            class: _vm.isDisabled ? "disabled" : "budget_total",
            attrs: { disabled: _vm.isDisabled },
            on: { click: _vm.submit },
          },
          [_vm._v("提交")]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }