<template>
	<section class="container">
		<div class="scoreBox">
			<div class="title" :title="scoreTitle">{{scoreTitle}}<span class="color">*</span></div>
			<div class="level">
                <i-rate  v-model="scoreVal" color="#ffcd30" :size="23"  @change="scoreMethod(scoreVal)" :readonly="disabled"/>
                <span class="ml10">{{scoreLevel}}</span>
            </div>
		</div>
		<div v-if="!isRequired||(isRequired&&scoreVal<3)" class="text">
			<textarea :placeholder="placeholder" v-model="scoreDescr" :disabled="disabled"></textarea>
		</div>
	</section>
</template>

<script>
export default {
    name: "score",
    props: ["scoreTitle", "placeholder","isRequired","scoreVal","scoreDescr","scoreLevel","disabled"],
    components: {},
    data() {
        return {
            
        };
    },
    created() {
        
    },
    methods: {
        scoreMethod(item) {
            switch (item) {
                case 1:
                    this.scoreLevel = "非常不满意";
                    break;
                case 2:
                    this.scoreLevel = "不太满意";
                    break;
                case 3:
                    this.scoreLevel = "一般";
                    // this.scoreDescr="";
                    break;
                case 4:
                    this.scoreLevel = "满意";
                    // this.scoreDescr="";
                    break;
                case 5:
                    this.scoreLevel = "非常满意";
                    // this.scoreDescr="";
                    break;
            }
        }
    }
};
</script>

<style scoped="scoped" lang="less">
* {
    box-sizing: border-box;
}
.container {
    background: #fff;
    padding: 0 .15rem;
}
.title {
    color: #666666;
    font-size: .14rem;
    width: 100%;
    margin: .1rem 0;
}
.ml10{
    margin-left:.1rem;
}
.content {
    margin-top: 0.44rem;
}
.level {
    font-size: .14rem;
    span{
        color:var(--themeColor);
    }
}
textarea {
    width: 100%;
    height: 90px;
    border: 1px solid #cccccc;
    outline: none;
    padding: 10px;
}
.scoreBox {
    display: flex;
    -webkit-display: flex;
    flex-direction: column;
    margin-bottom: .1rem;
    
}
textarea::-webkit-input-placeholder {
    color: #cccccc;
}
textarea::-moz-input-placeholder {
    color: #cccccc;
}
textarea::-ms-input-placeholder {
    color: #cccccc;
}
.text {
    padding-bottom: .1rem;
}
.color {
    color:red;
    padding-left:5px;
}

/* @media only screen and (max-width: 416px) {
    .container {
        padding: 0 10px;
    }
    .title {
        font-size: 12px;
    }
    .level {
        font-size: 12px;
    }

} */
</style>